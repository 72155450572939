// import React from "react";
// import logo from "../../assests/images/logo.png";
// import logo_black from "../../assests/images/logo_black.png";
// import "../../assests/css/Header.css";
// import { useNavigate } from "react-router-dom";

// const Header = (props) => {
//   let navigate = useNavigate();

//   return (
//     <div className="header">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="header_full">
//               <div className="mr-5">
//                 <a href="/">
//                   <img
//                     className="header_logo"
//                     src={props.black == true ? logo_black : logo}
//                     alt="main logo"
//                   />
//                 </a>
//               </div>
//               <div
//                 className={
//                   props?.black == true ? "header_links_black " : "header_links"
//                 }
//               >
//                 <div className="hdr_content_spilt">
//                   <span
//                     onClick={() => {
//                       navigate("/");
//                     }}
//                     className="mb-2"
//                   >
//                     Home
//                   </span>
//                   <span
//                     onClick={() => {
//                       navigate("/aboutus");
//                     }}
//                   >
//                     About
//                   </span>
//                 </div>
//                 <div className="hdr_content_spilt">
//                   <span
//                     onClick={() => {
//                       navigate("/community");
//                     }}
//                     className="mb-2"
//                   >
//                     Services
//                   </span>
//                   <span
//                     onClick={() => {
//                       navigate("/lifebelowwater");
//                     }}
//                   >
//                     Blogs
//                   </span>
//                 </div>
//                 <div className="hdr_content_spilt">
//                   <span
//                     onClick={() => {
//                       navigate("/faqs");
//                     }}
//                     className="mb-2"
//                   >
//                     Faqs
//                   </span>
//                   <span
//                     onClick={() => {
//                       navigate("/givers");
//                     }}
//                   >
//                     Givers
//                   </span>
//                 </div>
//                 <div className="hdr_content_spilt">
//                   <span
//                     onClick={() => {
//                       navigate("/community-content");
//                     }}
//                     className="mb-2"
//                   >
//                     Community Content
//                   </span>
//                   <span
//                     onClick={() => {
//                       navigate("/blog-categories");
//                     }}
//                   >
//                     Blog Category
//                   </span>
//                 </div>

//                 <div className="hdr_content_spilt">
//                   <span
//                     onClick={() => {
//                       navigate("/partnership");
//                     }}
//                     className="mb-2"
//                   >
//                     Partner Ship
//                   </span>
//                   <span
//                     onClick={() => {
//                       navigate("/recievers");
//                     }}
//                   >
//                     Recievers
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Header;

import React, { useEffect, useState } from "react";
import logo from "../../assests/images/logo.png";
import logo_black from "../../assests/images/logo_black.png";
import "../../assests/css/Header.css";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
	function useScrollDirection() {
		const [scrollDirection, setScrollDirection] = useState(null);
		useEffect(() => {
			let lastScrollY = window.pageYOffset;

			const updateScrollDirection = () => {
				const scrollY = window.pageYOffset;
				const direction = scrollY > lastScrollY ? "down" : "up";
				if (
					direction !== scrollDirection &&
					(scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
				) {
					setScrollDirection(direction);
				}
				lastScrollY = scrollY > 0 ? scrollY : 0;
			};
			window.addEventListener("scroll", updateScrollDirection); // add event listener
			return () => {
				window.removeEventListener("scroll", updateScrollDirection); // clean up
			};
		}, [scrollDirection]);

		return scrollDirection;
	}
	const scrollDirection = useScrollDirection();
	let navigate = useNavigate();

	return (
		<div
			className={`sticky ${
				scrollDirection === "down" ? "-top-24" : "top-0"
			} header`}
		>
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12">
						<div className="header_full">
							<div className="mr-5 logo-wrapper">
								<a href="/">
									<img
										className="header_logo"
										src={props.black == true ? logo_black : logo}
										alt="main logo"
									/>
								</a>
							</div>
							<div
								className={
									props?.black == true ? "header_links_black " : "header_links"
								}
							>
								<div className="hdr_content_spilt">
									<span
										onClick={() => {
											setTimeout(() => {
												window.location.reload();
											}, 100);
											navigate("/");
										}}
										className="mb-2"
									>
										Home
									</span>
									<span
										onClick={() => {
											setTimeout(() => {
												window.location.reload();
											}, 100);
											navigate("/aboutus");
										}}
									>
										About Us
									</span>
								</div>
								<div className="hdr_content_spilt">
									<span
										onClick={() => {
											setTimeout(() => {
												window.location.reload();
											}, 100);
											// navigate("/space-sustainibility");
											navigate("/savenature");
										}}
										className="mb-2"
									>
										Earth Sustainability
									</span>
									<span
										onClick={() => {
											setTimeout(() => {
												window.location.reload();
											}, 100);
											navigate("/savespace");
										}}
									>
										Space Sustainability
									</span>
								</div>
								<div className="hdr_content_spilt">
									<span
										onClick={() => {
											// setTimeout(() => {
											// 	window.location.reload();
											// }, 100);
											navigate("/givers");
										}}
									>
										Givers
									</span>
									<span
										className="mt-2"
										onClick={() => {
											setTimeout(() => {
												window.location.reload();
											}, 100);
											navigate("/recievers");
										}}
									>
										Receivers
									</span>
								</div>
								<div className="hdr_content_spilt">
									<span
										onClick={() => {
											setTimeout(() => {
												window.location.reload();
											}, 100);
											navigate("/partnership");
										}}
										className="mb-2"
									>
										Partners
									</span>
									<span
										onClick={() => {
											setTimeout(() => {
												window.location.reload();
											}, 100);
											navigate("/recievers");
										}}
									>
										Investors
									</span>
								</div>
								<div className="hdr_content_spilt">
									<span
										onClick={() => {
											setTimeout(() => {
												window.location.reload();
											}, 100);
											navigate("/community");
										}}
										className="mb-2"
									>
										App
									</span>
									<span
										onClick={() => {
											setTimeout(() => {
												window.location.reload();
											}, 100);
											navigate("/cryptotoken");
										}}
									>
										Token
									</span>
								</div>

								<div className="hdr_content_spilt">
									<span
										onClick={() => {
											setTimeout(() => {
												window.location.reload();
											}, 100);
											navigate("/community");
										}}
										className="mb-2"
									>
										Community
									</span>
									<span
										onClick={() => {
											setTimeout(() => {
												window.location.reload();
											}, 100);
											navigate("/blog-categories");
										}}
									>
										Blog
									</span>
								</div>

								<div className="hdr_content_spilt"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
