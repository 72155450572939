import React, { useRef } from "react";
import "../../assests/css/Cards_Section.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import RemoveIcon from "@mui/icons-material/Remove";

import card_img1 from "../../assests/images/savenature/img1.png";
import card_img2 from "../../assests/images/savenature/img2.png";
import card_img3 from "../../assests/images/savenature/img3.png";
import card_img4 from "../../assests/images/savenature/img4.png";
import card_img5 from "../../assests/images/savenature/img5.png";
import card_img6 from "../../assests/images/savenature/img6.png";
import card_img7 from "../../assests/images/savenature/img7.png";
import card_img8 from "../../assests/images/savenature/img8.png";
import card_img9 from "../../assests/images/savenature/img9.png";
import card_img10 from "../../assests/images/savenature/img10.png";
import card_img11 from "../../assests/images/savenature/img11.png";
import card_img12 from "../../assests/images/savenature/img12.png";
import card_img13 from "../../assests/images/savenature/img13.png";
import card_img14 from "../../assests/images/savenature/img14.png";
import { useSelector } from "react-redux";
import { useState } from "react";

const Cards_Section = () => {
	const { SavenatureData } = useSelector((item) => item.saveNature);
	console.log(SavenatureData, "sasassdasda");
	let myRef = useRef([]);

	let arr = [
		{
			id: 1,
			image: card_img1,
			heading: "NO POVERTY",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 2,
			image: card_img2,
			heading: "ZERO HUNGER",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 3,
			image: card_img3,
			heading: "GOOD HEALTH AND WELL BEING",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 4,
			image: card_img4,
			heading: "QUALITY EDUCATION",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 5,
			image: card_img5,
			heading: "GENDER EQUALITY",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 6,
			image: card_img6,
			heading: "CLEAN WATER AND SANITATION",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 7,
			image: card_img7,
			heading: "AFFORDABLE AND CLEAN ENERGY",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 8,
			image: card_img8,
			heading: "DECENT WORK ECONOMIC GROWTH",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 9,
			image: card_img9,
			heading: "INDUSTRY, INNOVATION AND INFRASTRUCTURE",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 10,
			image: card_img10,
			heading: "SUSTAINABLE CITIES AND COMMUNITIES",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 11,
			image: card_img11,
			heading: "RESPONSIBLE CONSUMPTION AND PRODUCTION",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 12,
			image: card_img12,
			heading: "CLIMATE ACTION",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 13,
			image: card_img13,
			heading: "LIFE BELOW WATER",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
		{
			id: 14,
			image: card_img14,
			heading: "LIFE ON LAND",
			description:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		},
	];

	const handleOnClick = (event) => {
		myRef[event].scrollIntoView(0);
	};
	const [handlemenu, SetHandleMenu] = useState(1);

	return (
		<div className="cards_section">
			<div className="container-fluid card-dl">
				<div className="row">
					<div className="col-lg-3 pos-rel">
						<div className="fix_pagination">
							<div className="side-link-wrapper">
								<div
									className={
										handlemenu == 1
											? "mt-4 pointer_cursor active"
											: "mt-4 pointer_cursor"
									}
								>
									<RemoveIcon />
									<span className="ml-0">NO POVERTY</span>
								</div>
								<div
									className={
										handlemenu == 2
											? "Side-link-child-wrapper active"
											: "Side-link-child-wrapper"
									}
								>
									{/* <RemoveIcon /> */}
									<span className="ml-0">ZERO HUNGER</span>
								</div>
							</div>
							<div className="side-link-wrapper">
								<div
									className={
										handlemenu == 3
											? "mt-4 pointer_cursor active"
											: "mt-4 pointer_cursor"
									}
								>
									<RemoveIcon />
									<span className="ml-0">GOOD HEALTH AND WELL-BEING</span>
								</div>
								<div
									className={
										handlemenu == 4
											? "Side-link-child-wrapper active"
											: "Side-link-child-wrapper"
									}
								>
									{/* <RemoveIcon /> */}
									<span className="ml-0">QUALITY EDUCATION</span>
								</div>
							</div>
							<div className="side-link-wrapper">
								<div
									className={
										handlemenu == 5
											? "mt-4 pointer_cursor active"
											: "mt-4 pointer_cursor"
									}
								>
									<RemoveIcon />
									<span className="ml-0">GENDER EQUALITY</span>
								</div>
								<div
									className={
										handlemenu == 6
											? "mt-4 pointer_cursor active"
											: "mt-4 pointer_cursor"
									}
								>
									{/* <RemoveIcon /> */}
									<span className="ml-0">CLEAN WATER AND SANITATION</span>
								</div>
							</div>
							<div className="side-link-wrapper">
								<div className="mt-4 pointer_cursor">
									<RemoveIcon />
									<span className="ml-0">AFFORDABLE AND CLEAN ENERGY</span>
								</div>
								<div className="Side-link-child-wrapper">
									{/* <RemoveIcon /> */}
									<span className="ml-0">DECENT WORK AND ECONOMIC GROWTH</span>
								</div>
							</div>
							<div className="side-link-wrapper">
								<div className="mt-4 pointer_cursor">
									<RemoveIcon />
									<span className="ml-0">
										INDUSTRY, INNOVATION AND INFRASTRUCTURE
									</span>
								</div>
								<div className="Side-link-child-wrapper">
									{/* <RemoveIcon /> */}
									<span className="ml-0">
										SUSTAINABLE CITIES AND COMMUNITIES
									</span>
								</div>
							</div>
							<div className="side-link-wrapper">
								<div className="mt-4 pointer_cursor">
									<RemoveIcon />
									<span className="ml-0">
										RESPONSIBLE CONSUMPTION AND PRODUCTION
									</span>
								</div>
								<div className="Side-link-child-wrapper">
									{/* <RemoveIcon /> */}
									<span className="ml-0">CLIMATE ACTION</span>
								</div>
							</div>
							<div className="side-link-wrapper">
								<div className="mt-4 pointer_cursor">
									<RemoveIcon />
									<span className="ml-0">LIFE BELOW WATER</span>
								</div>
								<div className="Side-link-child-wrapper">
									{/* <RemoveIcon /> */}
									<span className="ml-0">LIFE ON LAND</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-9">
						<div className="row">
							{SavenatureData?.data?.natures?.data.map((item, index) => {
								return (
									<div
										ref={(ref) => {
											myRef[index] = ref;
										}}
										id={item.id}
										key={index}
										className={`${
											index % 2 === 0 ? "col-lg-6 " : "col-lg-6 mt-card"
										}`}
										onMouseOver={() => SetHandleMenu(item.id)}
									>
										<div
											style={{
												backgroundImage: `url(${item.image_url}${item.image})`,
											}}
											className="crd-bg-img pos-rel"
										>
											<div className="hovr_cards_content">
												<p className="card_heading">{item.title}</p>
												<p className="card_desc">{item.description}</p>
											</div>
										</div>
										<div className="brdr-btm-gry">
											<p className="card_bottom_heading">{item.title}</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cards_Section;
